import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Events from '../components/Events';

function EventsPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Wellspring BCST`, `Wellspring Cranio`, `Wellspring Biodynamic Craniosacral Therapy`, `events`]}
        title="Events"
      />

      <Events />
    </Layout>
  );
}

export default EventsPage;
