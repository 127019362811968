import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

function Events() {
  const { motherAndBabe, embodiedPresence, barbGaske, bodyIntelligenceCalgary } = useStaticQuery(graphql`
    query {
      motherAndBabe: file(relativePath: { eq: "mother-and-babe.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      embodiedPresence: file(relativePath: { eq: "embodied-presence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      barbGaske: file(relativePath: { eq: "barb-gaske.jpg" }) {
        childImageSharp {
          fixed(height: 450) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bodyIntelligenceCalgary: file(relativePath: { eq: "body-intelligence-calgary.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="px-8 md:px-16 lg:max-w-screen-lg mx-auto">
      <h1 className="my-8 md:my-12 lg:my-16 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider text-center">
        Events
      </h1>

      <div className="mt-16">
        <Img
          className="my-6 md:my-12 mx-4"
          fluid={motherAndBabe.childImageSharp.fluid}
          alt="Body Intelligence, Calgary"
        />

        <h2 className="text-center mt-12 mb-6 md:mt-12 md:mb-8 lg:mt-16 lg:mb-8 font-semibold text-lg md:text-2xl text-gray-700 tracking-wider">
          In-Home Postpartum BCST for Mother and Babe
        </h2>

        <div className="mx-6 md:mx-24 mt-12 md:mb-24">
          <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
            Women are the heart, the foundation of the people. Supporting Mothers supports healthy and empowered families, which supports thriving communities, which supports all of humanity.
          </blockquote>
          <cite className="block mt-4 text-xs font-bold text-right uppercase">
            - Rachelle Garcia Seliga
          </cite>
        </div>

        <p className="my-4">
          I deeply align with <OutboundLink className="no-underline hover:underline text-blue-500" href="https://www.innatetraditions.com/" target="_blank" rel="noopener noreferrer">Innate Traditions</OutboundLink> and their primary commitment to maternal health and the revival of true postpartum care. In-home postpartum BCST for mums and babes is a deeply heartfelt service to support this movement in our culture toward centring care on mothers and honouring this profound time of the 4th trimester. Please reach out through the <Link to="/appointments#appointment-form" className="no-underline hover:underline text-blue-500">request form</Link> if you would like an in-home BCST session or would like to gift one to a family expecting or newly postpartum. You can also call/text directly <a href="tel:+17802162609" className="no-underline hover:underline text-blue-500">780.216.2609</a>. I’m always willing to be creative to find a time that suits as soon as possible.
        </p>

        <hr className="w-4/6 my-16 mx-auto" />

        <Img
          className="my-6 md:my-12 mx-4"
          fluid={bodyIntelligenceCalgary.childImageSharp.fluid}
          alt="Body Intelligence, Calgary"
        />

        <h2 className="text-center mt-12 mb-6 md:mt-12 md:mb-8 lg:mt-16 lg:mb-8 font-semibold text-lg md:text-2xl text-gray-700 tracking-wider">
          Biodynamic Craniosacral Therapy Training with Body Intelligence Practitioner Program
        </h2>

        <p className="my-4">
          Interested in becoming a Craniosacral Therapist? Body Intelligence offers a fully accredited 2-year practitioner course in Biodynamic Craniosacral Therapy in Calgary, AB. To find out more, join the next FREE Introduction to BCST on Zoom.
        </p>

        <p className="my-4">
          The Intro Talk is designed to provide a taste of this exciting and evolving body therapy science. You will explore the nature of Health and how to feel the expressions of Health as subtle biodynamic motions in the body. We will discuss trauma and how its effects are safely resolved with BCST. You will have a chance to meet some of the course tutors and discuss the course structure and material.
        </p>

        <p className="my-4">
          {/* <OutboundLink className="no-underline hover:underline text-blue-500" href="https://www.bodyintelligence.com/practitioner-foundation-training/find-a-course-starting-soon" target="_blank" rel="noopener noreferrer">Click here for dates</OutboundLink> */}
          <OutboundLink className="no-underline hover:underline text-blue-500" href="https://linktr.ee/wellspringcranio" target="_blank" rel="noopener noreferrer">Click here to register</OutboundLink>
        </p>

        <hr className="w-4/6 my-16 mx-auto" />

        <Img
          className="my-6 md:my-12 mx-4"
          fluid={embodiedPresence.childImageSharp.fluid}
          alt="person with horse"
        />

        <h2 className="text-center mt-12 mb-6 md:mt-12 md:mb-8 lg:mt-16 lg:mb-8 font-semibold text-lg md:text-2xl text-gray-700 tracking-wider">
          Embodied Presence &mdash; A Craniosacral Therapy and Equine Facilitated Learning Experience
        </h2>

        <p className="my-4">
          What is it that we deeply yearn for? Although we may think it is something outside of ourselves, it is the longing for our own presence that we truly desire.   Presence requires slowing way down to be able to tune into all our parts &mdash; emotional, mental, spiritual and physical.  It is this ability to &apos;tune in&apos; that gives us a better sense of who we are and how we relate to our world and others.
        </p>

        <p className="my-4">
          In this 3 hour private session, Barb and Angela will facilitate an experience of Embodied Presence with Biodynamic Craniosacral Therapy to regulate the nervous system and Equine Facilitated Learning to foster presence.  This session includes 2 Equine Facilitated Learning exercises with a 60 minute craniosacral session in between.  This is self-care that brings you into your immediate experience in a new way, with space to cleanse your perceptions, trust in the intelligence of the body, and empathetic connection.
        </p>

        <p className="my-4">
          This work can build upon itself.  Although one session may be of benefit, this program is designed to be repeated, building a momentum of learning and healing.  New Equine Facilitated Learning exercises will be selected to support the development and practice of presence; consecutive craniosacral therapy sessions will support the body’s self repair and regulation into deeper levels.  3 session packages of Embodied Presence experiences are available and recommended.
        </p>

        <p className="my-4">
          <OutboundLink className="no-underline hover:underline text-blue-500" href="http://www.reclaimconnection.ca/#idskgsrj" target="_blank" rel="noopener noreferrer">Contact Barb</OutboundLink> for more information on investment and availability.
        </p>

        <hr className="w-4/6 my-16 mx-auto" />

        <div className="md:flex mx-4">
          <div className="">
            <Img
            className="my-4"
            fixed={barbGaske.childImageSharp.fixed}
            alt="Barb Gaske"
            />
          </div>

          <div className="ml-4">
            <h4 className="mt-2 text-lg md:text-xl font-bold tracking-wide text-blue-800">
              Meet Barb Gaske
            </h4>

            <p className="font-bold">
              B.Sc (Pharm.), B.A., M.T.S. (Spirituality)
            </p>

            <p className="mt-4">
              Certified in:
            </p>

            <p className="">
              Power of the Herd&reg;, Master Herder&reg;, Sentient Communication&reg;, and Connection Focused Therapy&reg;
            </p>

            <p className="mt-4 mb-16">
              Barb&apos;s quest to understand what it really means to heal, and be whole, has led her to the field of Equine Facilitated Learning, where she is able to partner with her horses to help people on a road to healing and self-discovery.
              <br />
              <OutboundLink className="no-underline hover:underline text-blue-500 text-sm" href="http://www.reclaimconnection.ca/" target="_blank" rel="noopener noreferrer">reclaimconnection.ca</OutboundLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
